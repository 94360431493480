/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import muiSlider, { SliderRoot, SliderThumb, SliderTrack } from '@mui/material/Slider';
import { styled } from '@mui/material';
import { Card } from '../card';
import { Icon } from '../icon';

function isIos() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on isIos 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

const trackGradient = angle =>
  `linear-gradient(${angle}deg, rgb(193, 0, 185) 0%, rgb(110, 0, 212) 100%) padding-box padding-box`;

const StyledSlider = styled(muiSlider)({
  position: 'static',
  boxSizing: 'border-box',
  height: '100%',

  '& .MuiSlider-thumb': {
    width: '68px',
    height: '50px',
    borderRadius: '14px',

    border: '3px solid transparent',
    background:
      'linear-gradient(-16deg, #6e00d4 0%, #C100B9 100%) padding-box, linear-gradient(-16deg, #C100B9 0%, #6e00d4 100%) border-box;',
    boxShadow: '2px 2px 2px 0 #1d1d1d',

    '::before': {
      boxShadow: 'none',
    },
  },

  '& .MuiSlider-track': {
    height: '50px',
    background: trackGradient(90),
    border: 'none',
    borderRadius: 'unset',
    minWidth: '50px',
  },

  '& .MuiSlider-rail': {
    backgroundColor: 'transparent',
  },
});

function ThumbComponent({ children, ...props }) {
  const thumbRef = useRef();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (thumbRef.current) {
      const { offsetWidth, parentElement } = thumbRef.current;
      if (parentElement) {
        setOffset((100 / parentElement.offsetWidth) * (offsetWidth / 2));
      }
    }
  }, []);

  const { value, min, max } = props.ownerState;

  const left = ((value - min) * 100) / (max - min);
  const fixedLeft = Math.max(offset, Math.min(left, 100 - offset));

  return (
    <div
      style={{
        position: 'absolute',
        inset: '50% 0px 50%',
        margin: 'auto',
      }}
    >
      <SliderThumb {...props} style={{ left: `${fixedLeft}%` }} ref={thumbRef}>
        {children}
        <Icon i="arrow" size="32px" />
      </SliderThumb>
    </div>
  );
}

function TrackComponent({ children, ...props }) {
  let { style } = props;
  style.background = trackGradient(props.ownerState.value * 1.5);

  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
      }}
    >
      <div>
        <SliderTrack style={style} {...props}>
          {children}
        </SliderTrack>
      </div>
    </div>
  );
}

export const Slider = ({ text, style, onChange, ...props }) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const updateValue = (event, sliderValue) => {
    setValue(Number(sliderValue));
  };
  return (
    <div style={{ ...style, marginInline: 'auto', position: 'relative' }}>
      <Card
        variant="invert"
        style={{
          borderRadius: '14px',
          overflow: 'hidden',
          margin: 0,
          padding: 0,
          height: '50px',
        }}
      >
        <span
          style={{
            position: 'absolute',
            left: '0',
            right: '0',
            margin: 'auto',
            bottom: '50%',
            textAlign: 'center',
            height: '50%',
            top: '4px',
            fontSize: '28px',
            letterSpacing: '1.3px',
            opacity: 1 - (value / 100) * 3,
          }}
        >
          {text || ''}
        </span>
        <StyledSlider
          slots={{ root: SliderRoot, thumb: ThumbComponent, track: TrackComponent }}
          {...props}
          value={value}
          onChange={(...params) => {
            if (isIos() && params[0]?.type === 'mousedown') return;
            updateValue(...params);
            onChange(...params);
          }}
        />
      </Card>
    </div>
  );
};
