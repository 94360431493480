export default function SVGBanner() {
  return (
    <svg x="8" y="8" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9933 0.857117V2.24997M20.3452 17.5714H23.1292M14.7773 17.5714H11.9933V23.1428M11.9933 7.8214V12M11.9933 12H12.0072M11.9933 12H17.5752M17.5613 23.1428H23.1292M0.857422 12H6.42537M23.1292 12H23.1431M2.24941 6.42855H5.03338C5.40256 6.42855 5.75662 6.2818 6.01767 6.02059C6.27872 5.75938 6.42537 5.4051 6.42537 5.03569V2.24997C6.42537 1.88057 6.27872 1.52629 6.01767 1.26508C5.75662 1.00386 5.40256 0.857117 5.03338 0.857117H2.24941C1.88023 0.857117 1.52617 1.00386 1.26513 1.26508C1.00408 1.52629 0.857422 1.88057 0.857422 2.24997V5.03569C0.857422 5.4051 1.00408 5.75938 1.26513 6.02059C1.52617 6.2818 1.88023 6.42855 2.24941 6.42855ZM18.9533 6.42855H21.7372C22.1064 6.42855 22.4605 6.2818 22.7215 6.02059C22.9826 5.75938 23.1292 5.4051 23.1292 5.03569V2.24997C23.1292 1.88057 22.9826 1.52629 22.7215 1.26508C22.4605 1.00386 22.1064 0.857117 21.7372 0.857117H18.9533C18.5841 0.857117 18.23 1.00386 17.969 1.26508C17.7079 1.52629 17.5613 1.88057 17.5613 2.24997V5.03569C17.5613 5.4051 17.7079 5.75938 17.969 6.02059C18.23 6.2818 18.5841 6.42855 18.9533 6.42855ZM2.24941 23.1428H5.03338C5.40256 23.1428 5.75662 22.9961 6.01767 22.7349C6.27872 22.4737 6.42537 22.1194 6.42537 21.75V18.9643C6.42537 18.5949 6.27872 18.2406 6.01767 17.9794C5.75662 17.7181 5.40256 17.5714 5.03338 17.5714H2.24941C1.88023 17.5714 1.52617 17.7181 1.26513 17.9794C1.00408 18.2406 0.857422 18.5949 0.857422 18.9643V21.75C0.857422 22.1194 1.00408 22.4737 1.26513 22.7349C1.52617 22.9961 1.88023 23.1428 2.24941 23.1428Z"
        stroke="white"
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
