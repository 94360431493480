/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react';
import '@maptiler/sdk/dist/maptiler-sdk.css';
import './Map.css';
import * as maptilersdk from '@maptiler/sdk';
import maplibregl from 'maplibre-gl';

export default function Map({ tokaPorts, ugvPorts, km220Ports }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = 31.223;
  const lat = 49.454;
  const zoom = 4;
  //todo extract to github secrets
  const API_KEY = 'fysAbJpnonqUYEipElqb';
  maptilersdk.config.apiKey = API_KEY;

  useEffect(() => {
    if (map.current) {
      return;
    } // stops map from intializing more than once

    map.current = new maptilersdk.Map({
      container: mapContainer.current,
      style: maptilersdk.MapStyle.STREETS,
      //       navigationControl: false,

      center: [lng, lat],
      zoom: zoom,
      // geolocate: maptilersdk.GeolocationType.POINT,

      geolocateControl: false,
      navigationControl: false,

      maptilerLogo: false,
      scaleControl: true,
    });

    drawGeolocateControl();
  }, []);

  const [tokaMarkers] = useState([]);
  useEffect(() => {
    if (!map.current) {
      return;
    }

    updateMarkers(tokaPorts, tokaMarkers);
  }, [tokaPorts]);

  const [ugvMarkers] = useState([]);
  useEffect(() => {
    if (!map.current) {
      return;
    }

    // console.log(ugvPorts);

    updateMarkers(ugvPorts, ugvMarkers);
  }, [ugvPorts]);

  const [km220Markers] = useState([]);
  useEffect(() => {
    if (!map.current) {
      return;
    }

    // console.log(km220Ports);

    updateKm220Markers(km220Ports, km220Markers);
  }, [km220Ports]);

  function drawGeolocateControl() {
    // Ensure the location circle and marker do not block pointer events
    map.current.on('load', () => {
      const geolocateControl = new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: false,
        },
        trackUserLocation: false,
      });

      map.current.addControl(geolocateControl, 'top-right');
      // Apply inline styles to the geolocate control and make it 3 times bigger
      const geolocateControlContainer = document.querySelector('.maplibregl-ctrl-geolocate');
      if (geolocateControlContainer) {
        geolocateControlContainer.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
        geolocateControlContainer.style.borderRadius = '8px';
        geolocateControlContainer.style.padding = '0px'; // Adjust padding
        geolocateControlContainer.style.transform = 'scale(2.1)'; // Scale it 1.5 times bigger
        geolocateControlContainer.style.transformOrigin = 'center'; // Ensure scaling is centered
        geolocateControlContainer.style.position = 'absolute'; // Set position to absolute
        geolocateControlContainer.style.top = '9px'; // Move it lower by adjusting top
        geolocateControlContainer.style.right = '10px'; // Adjust right position if needed
      }

      const locationCircle = document.querySelector('.maplibregl-user-location-dot');
      const locationAccuracyCircle = document.querySelector('.maplibregl-user-location-accuracy-circle');

      if (locationCircle) {
        locationCircle.style.display = 'none'; // Completely hide the accuracy circle
      }
      if (locationAccuracyCircle) {
        locationAccuracyCircle.style.display = 'none';
      }

      // Adjust z-index of the location circle to be under the markers
      if (locationCircle) {
        locationCircle.style.zIndex = '0'; // Set a lower z-index for the location circle
      }
      if (locationAccuracyCircle) {
        locationAccuracyCircle.style.zIndex = '0'; // Set a lower z-index for the accuracy circle
      }
    });
  }

  function updateMarkers(ports, markers) {
    markers.forEach(function (marker) {
      marker.remove();
    });

    ports?.forEach(function (port) {
      var { marker, el } = drawMarkerWithLocation(port);

      el.style.backgroundImage = port?.name?.includes('OVERPRICED')
        ? 'url(/portRoaming20.svg)'
        : `url(/portRoaming.svg)`;
      el.style.width = port?.name?.includes('OVERPRICED') ? '20px' : `30px`;
      el.style.height = port?.name?.includes('OVERPRICED') ? '20px' : `30px`;

      el.addEventListener('click', function () {
        // ReactGA.event({
        //   action: port?.deviceId,
        // });
        window.open(port?.deviceId);
      });

      el.className = port?.name?.includes('IN_PROGRESS') ? 'blink_me' : null;

      markers.push(new maptilersdk.Marker({ element: el }).setLngLat(marker.geometry.coordinates).addTo(map.current));
    });

    // console.log(markers);
  }

  function updateKm220Markers(ports, markers) {
    markers.forEach(function (marker) {
      marker.remove();
    });

    ports?.forEach(function (port) {
      var { marker, el } = drawMarkerWithLocation(port);

      if (port?.name?.includes('doNotShowOnMap')) {
        return;
      }

      if (port?.name?.includes('inService')) {
        el.style.backgroundImage = `url(/inService.svg)`;
      } else if (port?.name?.includes('dick')) {
        el.style.backgroundImage = `url(/moscow.svg)`;
      }
      else {
              el.className = (
                port?.job?.state === 'IN_PROGRESS' || port?.name?.includes('used')
              ) ?
               'blink_me' : null;
              el.style.backgroundImage = port.online ? `url(/port.svg)` : `url(/portOffline.svg)`;
            }

      el.addEventListener('click', function () {
        window.open(`/start?station=${port.number}`);
      });

      markers.push(new maptilersdk.Marker({ element: el }).setLngLat(marker.geometry.coordinates).addTo(map.current));
    });

    // console.log(markers);
  }

  function drawMarkerWithLocation(port) {
    var marker = {
      type: 'Feature',
      properties: {
        message: 'Baz',
      },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(`${port.longitude}`), parseFloat(`${port.latitude}`)],
      },
    };

    var el = document.createElement('div');
    el.style.width = '30px';
    el.style.height = '30px';
    el.style.zIndex = '1'; // Ensure markers have a higher z-index than the location circle
    return { marker, el };
  }

  function isIos() {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on isIos 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
    </div>
  );
}
