import cn from 'classnames';
import styles from './scroll-number.module.css';
import { motion, AnimatePresence } from 'framer-motion';

export const ScrollNumber = ({ direction = 'up', duration = 0.2, children, className, ...props }) => {
  return (
    <div className={styles.container} {...props}>
      <AnimatePresence>
        <motion.span
          className={cn(className)}
          style={{
            fontSize: '46px',
            letterSpacing: '4.3px',
          }}
          key={children}
          exit={{
            y: direction === 'up' ? -10 : 20,
            opacity: 0,
            position: 'absolute',
          }}
          initial={{
            y: direction === 'up' ? 20 : -10,
            opacity: 0,
            position: 'absolute',
          }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            ease: 'easeOut',
            duration,
          }}
        >
          {children}
        </motion.span>
      </AnimatePresence>
    </div>
  );
};
