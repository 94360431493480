const date = new Date();

const year = date.getFullYear();
const month = date.getMonth() + 1;

export const defaultMonthObj = (months, setDefaultMonth) => {
  if (month - 1 === 0) {
    setDefaultMonth(months[0]);
  } else {
    // eslint-disable-next-line
    months?.map(el => {
      if (el?.value === [year, month - 0].join('-')) {
        setDefaultMonth(el);
      }
    });
  }
};
